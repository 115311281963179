export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1)

export const toMinutesFromString = (time: string) => {
  const array = time.split('.')
  if (array.length === 2) {
    const dias = parseInt(array[0], 10)

    const array2 = array[1].split(':')
    const horas = parseInt(array2[0], 10)
    const minutos = parseInt(array2[1], 10)

    const tiempo = minutos + horas * 60 + dias * 24 * 60
    return tiempo
  } else {
    const array2 = time.split(':')
    const horas = parseInt(array2[0], 10)
    const minutos = parseInt(array2[1], 10)

    const tiempo = minutos + horas * 60
    return tiempo
  }
}

export const tipoValorHandler = (tipo: string) => {
  switch (tipo) {
    case 'menor':
      return '<'
    case 'menor-igual':
      return '≤'
    case 'mayor':
      return '>'
    case 'mayor-igual':
      return '≥'
  }
}

export const tipoValorTextoHandler = (tipo: string) => {
  switch (tipo) {
    case 'menor':
      return 'menor a'
    case 'menor-igual':
      return 'menor o igual a'
    case 'mayor':
      return 'mayor a'
    case 'mayor-igual':
      return 'mayor o igual a'
  }
}

export const mesesHandler = (meses: number[]) => {
  return meses
    .map((mes) => {
      switch (mes) {
        case 1:
          return 'Enero'
        case 2:
          return 'Febrero'
        case 3:
          return 'Marzo'
        case 4:
          return 'Abril'
        case 5:
          return 'Mayo'
        case 6:
          return 'Junio'
        case 7:
          return 'Julio'
        case 8:
          return 'Agosto'
        case 9:
          return 'Septiembre'
        case 10:
          return 'Octubre'
        case 11:
          return 'Noviembre'
        case 12:
          return 'Diciembre'
      }
    })
    .join(' - ')
}

export const procesoHandler = (proceso: string) => {
  switch (proceso) {
    case 'preanalitico':
      return 'Preanalítico'
    case 'postanalitico':
      return 'Postanalítico'
    case 'analitico':
      return 'Analítico'
  }
}

export const servicioHandler = (servicio: string) => {
  switch (servicio) {
    case 'laboratorio':
      return 'Laboratorio'
    case 'banco-sangre':
      return 'Banco de sangre'
  }
}

export const idiomaHandler = (idioma: string) => {
  switch (idioma) {
    case 'español':
      return 'Español'
    case 'english':
      return 'Inglés'
  }
}

export const meses = [
  {value: 1, label: 'Enero'},
  {value: 2, label: 'Febrero'},
  {value: 3, label: 'Marzo'},
  {value: 4, label: 'Abril'},
  {value: 5, label: 'Mayo'},
  {value: 6, label: 'Junio'},
  {value: 7, label: 'Julio'},
  {value: 8, label: 'Agosto'},
  {value: 9, label: 'Septiembre'},
  {value: 10, label: 'Octubre'},
  {value: 11, label: 'Noviembre'},
  {value: 12, label: 'Diciembre'},
]
