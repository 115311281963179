import axios from 'axios'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'
import {CreateIndicadorDTO} from '../../../../models/DTOs/indicador/CreateIndicadorDTO'

export const GET_INDICADORES = `${API_URL}/indicadores`
export const CREATE_INDICADOR = `${API_URL}/indicadores`
export const UPDATE_INDICADOR_STATUS = `${API_URL}/indicadores/:id/status`
export const GET_INDICADOR = `${API_URL}/indicadores/:id`
export const UPDATE_INDICADOR = `${API_URL}/indicadores/:id`
export const GET_INDICADOR_HISTORIAL = `${API_URL}/indicadores/:id/historial`
export const ASSIGN_INDICADORES_INSTITUCIONES = `${API_URL}/indicadores/instituciones`
export const LIST_ASSIGN_INDICADORES_INSTITUCIONES = `${API_URL}/indicadores/instituciones/:id`

export function getIndicadores() {
  return axios.get(GET_INDICADORES)
}

export function createIndicador(request: CreateIndicadorDTO) {
  return axios.post(CREATE_INDICADOR, request)
}

export function updateIndicadorStatus(indicadorId: string) {
  return axios.post(UPDATE_INDICADOR_STATUS.replace(':id', indicadorId))
}

export function getIndicador(indicadorId: string) {
  return axios.get(GET_INDICADOR.replace(':id', indicadorId))
}

export function updateIndicador(indicadorId: string, updateIndicador: CreateIndicadorDTO) {
  return axios.post(UPDATE_INDICADOR.replace(':id', indicadorId), updateIndicador)
}

export function getIndicadorHistorial(indicadorId: string) {
  return axios.get(GET_INDICADOR_HISTORIAL.replace(':id', indicadorId))
}

export function listAssignIndicadoresInstituciones(institucionIdEncoded: string) {
  return axios.get(LIST_ASSIGN_INDICADORES_INSTITUCIONES.replace(':id', institucionIdEncoded))
}

export function assignIndicadoresInstituciones(relaciones: {
  InstitucionId: string
  IndicadoresPredefinidosId: string[]
}) {
  return axios.post(ASSIGN_INDICADORES_INSTITUCIONES, relaciones)
}
