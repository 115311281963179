import moment from 'moment'
import {randomString} from '../../../../../_metronic/helpers/RandomString'
import {CreateInstitutionDTO} from '../../../../models/DTOs/institucion/CreateInstitutionDTO'
import {InstitucionAltaModel} from '../../../../models/InstitucionAltaModel'
import {PaisesTableMock} from './paisesTableMock'

const INSTITUCION_ALTA_KEY = 'InstitucionesAltasTableMock'

export class InstitucionesAltasTableMock {
  public static table: Array<InstitucionAltaModel> = [
    {
      Id: 'abcde1234567890',
      Nombre: 'LaboratorioDemo',
      Responsable: 'AdministradorLab',
      EmailResponsable: 'adminclient@demo.com',
      Ciudad: 'Buenos Aires',
      Pais: 'Argentina',
      Fecha: '14/07/2023',
      Idioma: 'español',
      InstitucionId: 'sm2Ake7UIOpe231',
      Servicio: 'laboratorio',
    },
  ]

  private static _table: Array<InstitucionAltaModel> = []

  private static save() {
    localStorage.setItem(INSTITUCION_ALTA_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const institucionesStorage = localStorage.getItem(INSTITUCION_ALTA_KEY)
    this._table = institucionesStorage !== null ? JSON.parse(institucionesStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static createInstitucionAlta(institucionId: string, info: CreateInstitutionDTO) {
    this.read()
    this._table.push({
      Id: randomString(15),
      InstitucionId: institucionId,
      Nombre: info.Nombre,
      Pais: PaisesTableMock.getTable().find((p) => p.Id === info.Pais)!.Nombre,
      Ciudad: info.Ciudad,
      Servicio: info.Servicio,
      Idioma: info.Idioma,
      Responsable: info.Responsable,
      EmailResponsable: info.EmailResponsable,
      Fecha: moment().format('DD/MM/YYYY'),
    })
    this.save()
  }
}
