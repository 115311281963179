import {toAbsoluteUrl} from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img
        src={toAbsoluteUrl('/media/logos/logo_gm_qi.png')}
        alt='Start logo'
        className='h-30px'
      />
      <span>Loading ...</span>
    </div>
  )
}
