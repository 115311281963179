import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {UserModel} from '../../../models/UserModel'
import {getUserByToken} from './CRUD/AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Loggedout: '[Loggedout] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  UpdateToken: '[UpdateToken] Action',
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  refreshToken?: string
  hash?: string
}

export const reducer = persistReducer(
  {
    storage,
    key: process.env.REACT_APP_AUTH_KEY!,
    whitelist: ['user', 'accessToken', 'refreshToken'],
  },
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        const refreshToken = action.payload?.refreshToken
        return {accessToken, refreshToken, user: undefined}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user}
      }

      case actionTypes.UpdateToken: {
        const accessToken = action.payload?.accessToken
        const refreshToken = action.payload?.refreshToken
        return {...state, accessToken, refreshToken}
      }

      default:
        return state
    }
  }
)

export const actions = {
  login: (accessToken: string, refreshToken: string) => ({
    type: actionTypes.Login,
    payload: {accessToken, refreshToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  updateToken: (accessToken: string, refreshToken: string) => ({
    type: actionTypes.UpdateToken,
    payload: {accessToken, refreshToken},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data: user} = yield getUserByToken()
    yield put(actions.fulfillUser(user))
  })
}
