import {randomString} from '../../../../../_metronic/helpers/RandomString'
import {CreateUsuarioDTO} from '../../../../models/DTOs/usuario/CreateUsuarioDTO'
import {UserModel} from '../../../../models/UserModel'
import {UsuarioDTO} from '../../../../models/DTOs/usuario/UsuarioDTO'
const USUARIO_KEY = 'UsuariosTableMock'

export class UsersTableMock {
  public static table: Array<UserModel> = [
    {
      Id: '1234567890abcde',
      Email: 'admin@demo.com',
      Rol: 'admin', // Administrator
      Nombre: 'Aldana AAA',
      IsActivo: true,
      Auth: {
        AccessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cc',
        RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
    },
    {
      Id: 'jdnduehsi3n5b6d',
      Email: 'user@demo.com',
      Rol: 'asesor', // Usuario
      Nombre: 'Valentin AAA',
      IsActivo: true,
      Auth: {
        AccessToken: 'access-token-8f3ae836da744329a6f93bf20594b5ca',
        RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
    },
    {
      Id: 'oaj39578mdnsjdi',
      Email: 'dylanperro@demo.com',
      Rol: 'asesor', // Usuario
      Nombre: 'Dylan Perro',
      IsActivo: false,
      Auth: {
        AccessToken: 'access-token-8f3ae836da744329a6f93bf20594b5cb',
        RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
    },
  ]

  private static _table: Array<UserModel> = []

  private static save() {
    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const usuariosStorage = localStorage.getItem(USUARIO_KEY)
    this._table = usuariosStorage !== null ? JSON.parse(usuariosStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static crearUsuario(newUserDTO: CreateUsuarioDTO): UserModel {
    const newUser: UserModel = {
      Id: randomString(15),
      IsActivo: true,
      Email: newUserDTO.Email,
      Nombre: newUserDTO.Nombre,
      Rol: newUserDTO.Rol,
      Auth: {
        AccessToken: 'access-token-' + randomString(32),
        RefreshToken: 'access-token-f8c137a2c98743f48b643e71161d90aa',
      },
    }
    this.read()
    this._table.push(newUser)
    this.save()
    return {...newUser}
  }

  static updateState(usuarioId: string) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel

    if (usuario === null) return

    usuario.IsActivo = !usuario.IsActivo

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }

  static updateUser(usuarioId: string, user: UsuarioDTO) {
    const usuario = this._table.find((x) => x.Id === usuarioId) as UserModel

    if (!usuario) return

    usuario.Nombre = user.Nombre
    usuario.Email = user.Email
    usuario.Rol = user.Rol

    localStorage.setItem(USUARIO_KEY, JSON.stringify(this._table))
  }
}
