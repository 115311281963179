import {PaisesDTO} from '../../../../models/DTOs/institucion/PaisesDTO'

const PAISES_KEY = 'PaisesTableMock'

export class PaisesTableMock {
  public static table: Array<PaisesDTO> = [
    {
      Id: 14,
      Nombre: 'Argentina',
    },
    {
      Id: 35,
      Nombre: 'Brasil',
    },
    {
      Id: 46,
      Nombre: 'Chile',
    },
    {
      Id: 157,
      Nombre: 'Mexico',
    },
  ]

  private static _table: Array<PaisesDTO> = []

  private static read() {
    const paisesStorage = localStorage.getItem(PAISES_KEY)
    this._table = paisesStorage !== null ? JSON.parse(paisesStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }
}
