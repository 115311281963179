import {Suspense, lazy} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import {RootState} from '../../setup'
import {FallbackView} from '../../_metronic/partials'
import {UserModel} from '../models/UserModel'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {Logout} from '../modules/auth'

export function PrivateRoutes() {
  const AccountPage = lazy(() => import('../pages/accounts/AccountPage'))
  const UsersList = lazy(() => import('../pages/users-list/UsersList'))
  const Tickets = lazy(() => import('../pages/tickets/Tickets'))
  const InstitutionsList = lazy(() => import('../pages/institutions-list/InstitutionsList'))
  const IndicatorsList = lazy(() => import('../pages/indicators-list/IndicatorsList'))
  const AsignarIndicadoresInstituciones = lazy(
    () => import('../pages/asignar-indicadores-instituciones/AsignarIndicadoresInstituciones')
  )
  const ResumenParticipacion = lazy(
    () => import('../pages/reportes/resumen-participacion/ResumenParticipacion')
  )
  const ParticipacionInstitucion = lazy(
    () => import('../pages/reportes/participacion-institucion/ParticipacionInstitucion')
  )
  const EstadoParticipacion = lazy(
    () => import('../pages/reportes/estado-participacion/EstadoParticipacion')
  )
  const MedicionesIndicador = lazy(
    () => import('../pages/reportes/mediciones-indicador/MedicionesIndicador')
  )

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const isAdmin = user.Rol === 'admin'

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />
        <Route path='/support/tickets' component={Tickets} />
        {isAdmin && <Route path='/userslist' component={UsersList} />}
        {isAdmin && <Route path='/institutions' component={InstitutionsList} />}
        <Route path='/indicators' component={IndicatorsList} />
        <Route path='/assign-indicators-institutions' component={AsignarIndicadoresInstituciones} />
        <Route path='/reports/participationsummary' exact component={ResumenParticipacion} />
        <Route
          path='/reports/institutionparticipation'
          exact
          component={ParticipacionInstitucion}
        />
        <Route path='/reports/participationstatus' exact component={EstadoParticipacion} />
        <Route path='/reports/indicatormeasurements' exact component={MedicionesIndicador} />
        <Route path='/account' component={AccountPage} />
        <Route path='/logout' component={Logout} />

        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
