import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useSelector, shallowEqual} from 'react-redux'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      <AsideMenuItem
        id='pagPrincipal'
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Administracion</span>
        </div>
      </div>
      {user.Rol === 'admin' && (
        <>
          <AsideMenuItem
            id='listaUsuarios'
            to='/userslist'
            icon='/media/icons/duotune/communication/com005.svg'
            title='Lista de usuarios'
            fontIcon='bi-app-indicator'
          />
          <AsideMenuItem
            id='listaInstituciones'
            to='/institutions'
            icon='/media/icons/duotune/finance/fin001.svg'
            title='Lista de instituciones'
            fontIcon='bi-app-indicator'
          />
        </>
      )}
      <AsideMenuItem
        id='listaIndicadores'
        to='/indicators'
        icon='/media/icons/duotune/general/gen032.svg'
        title='Lista de indicadores'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        id='asignarIndicadoresInstituciones'
        to='/assign-indicators-institutions'
        icon='/media/icons/duotune/abstract/abs022.svg'
        title='Asignar indicadores a instituciones'
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItemWithSub
        title='Reportes'
        to='/individualreports'
        fontIcon='bi-app-indicator'
        icon='/media/icons/duotune/files/fil003.svg'
      >
        <AsideMenuItem
          id='ResumenDeParticipacion'
          to='/reports/participationsummary'
          hasBullet
          title='Resumen de participación'
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          id='ParticipacionPorInstitucion'
          to='/reports/institutionparticipation'
          hasBullet
          title='Participación por institución'
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          id='EstadoDeParticipacion'
          to='/reports/participationstatus'
          hasBullet
          title='Estado de participación'
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          id='MedicionesPorIndicador'
          to='/reports/indicatormeasurements'
          hasBullet
          title='Mediciones por indicador'
          fontIcon='bi-app-indicator'
        />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Soporte</span>
        </div>
      </div>
      <AsideMenuItem
        id='pagPrincipal'
        to='/support/tickets'
        icon='/media/icons/duotune/communication/com007.svg'
        title='Tickets'
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
