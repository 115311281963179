import {API_URL} from '../../_metronic/helpers/API_URLHelper'
import * as auth from '../../app/modules/auth/redux/AuthRedux'

export default function setupAxios(axios: any, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => {
      return response
    },
    async (error: any) => {
      const originalRequest = error.config

      if (error.response?.status === 401 && !originalRequest._retry) {
        const {
          auth: {refreshToken},
        } = store.getState()

        return axios
          .post(`${API_URL}/auth/token/refresh`, {}, {headers: {'x-refreshtoken': refreshToken}})
          .then((r: any) => {
            const tokens = r
            store.dispatch(
              auth.actions.updateToken(tokens.data.AccessToken, tokens.data.RefreshToken)
            )
            originalRequest._retry = true
            return axios(originalRequest)
          })
          .catch((e: any) => {
            store.dispatch(auth.actions.logout())
            return Promise.reject(e)
          })
      }
      return Promise.reject(error)
    }
  )
}
