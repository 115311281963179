import {randomString} from '../../../../../_metronic/helpers/RandomString'
import {CreateInstitutionDTO} from '../../../../models/DTOs/institucion/CreateInstitutionDTO'
import {UpdateInstitucionDTO} from '../../../../models/DTOs/institucion/UpdateInstitucionDTO'
import {InstitutionModel} from '../../../../models/InstitutionModel'

const INSTITUTION_KEY = 'InstitucionesTableMock'

export class InstitutionsTableMock {
  public static table: Array<InstitutionModel> = [
    {
      Id: 'sm2Ake7UIOpe231',
      Nombre: 'LaboratorioDemo',
      Responsable: 'AdministradorLab',
      EmailResponsable: 'adminclient@demo.com',
      IsActivo: true,
    },
  ]

  private static _table: Array<InstitutionModel> = []

  private static save() {
    localStorage.setItem(INSTITUTION_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const institucionesStorage = localStorage.getItem(INSTITUTION_KEY)
    this._table = institucionesStorage !== null ? JSON.parse(institucionesStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static updateState(institucionId: string) {
    const institucion = this._table.find((x) => x.Id === institucionId) as InstitutionModel
    if (institucion === null) return

    institucion.IsActivo = !institucion.IsActivo

    localStorage.setItem(INSTITUTION_KEY, JSON.stringify(this._table))
  }

  static crearInstitucion(newInstitutionDTO: CreateInstitutionDTO): InstitutionModel {
    const newInstitution: InstitutionModel = {
      Id: randomString(15),
      IsActivo: true,
      EmailResponsable: newInstitutionDTO.EmailResponsable,
      Nombre: newInstitutionDTO.Nombre,
      Responsable: newInstitutionDTO.Responsable,
    }
    this.read()
    this._table.push(newInstitution)
    this.save()
    return {...newInstitution}
  }

  static updateInstitution(institucionId: string, institucionUpdate: UpdateInstitucionDTO) {
    const institucion = this._table.find((x) => x.Id === institucionId) as InstitutionModel

    if (institucion === null) return

    institucion.Nombre = institucionUpdate.Nombre
    institucion.EmailResponsable = institucionUpdate.EmailResponsable
    institucion.Responsable = institucionUpdate.Responsable

    localStorage.setItem(INSTITUTION_KEY, JSON.stringify(this._table))
  }
}
