import {randomString} from '../../../../../_metronic/helpers/RandomString'
import {CreateIndicadorDTO} from '../../../../models/DTOs/indicador/CreateIndicadorDTO'
import {IndicadorModel} from '../../../../models/IndicadorModel'

const INDICADORES_KEY = 'IndicadoresTableMock'

export class IndicadoresTableMock {
  public static table: Array<IndicadorModel> = [
    {
      Id: 'msk93290jiuoSIa',
      Nombre: 'Acciones y/o Oportunidades de Mejora cerradas a tiempo y/o con tratamiento',
      Codigo: 'asdqwe1234',
      Proceso: 'postanalitico',
      IsActivo: true,
      Calculos: 'eD0yK3g=',
      Definicion:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      FormatoExpresion: 'Formato AAA',
      FrecuenciaReporte: {
        Frecuencia: 'otro',
        Valores: [1],
      },
      MetodoRecoleccionDatos:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).",
      Referencias:
        "The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from 'de Finibus Bonorum et Malorum' by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.",
      ValorLimite: {
        Tipo: 'menor-igual',
        Valor: {
          Value: 100,
          Precision: 1,
        },
      },
      ValorObjetivo: {
        Tipo: 'mayor',
        Valor: {
          Value: 123,
          Precision: 1,
        },
      },
      Servicio: 'laboratorio',
    },
  ]

  private static _table: Array<IndicadorModel> = []

  private static save() {
    localStorage.setItem(INDICADORES_KEY, JSON.stringify(this._table))
  }

  private static read() {
    const indicadoresStorage = localStorage.getItem(INDICADORES_KEY)
    this._table = indicadoresStorage !== null ? JSON.parse(indicadoresStorage) : []
  }

  static getTable() {
    this.read()
    return [...this.table, ...this._table]
  }

  static createIndicador(indicador: CreateIndicadorDTO) {
    const indicadorModel: IndicadorModel = {
      Id: randomString(15),
      IsActivo: true,
      Nombre: indicador.Nombre,
      Calculos: indicador.Calculos,
      Codigo: indicador.Codigo,
      Definicion: indicador.Definicion,
      FormatoExpresion: indicador.FormatoExpresion,
      FrecuenciaReporte: indicador.FrecuenciaReporte,
      MetodoRecoleccionDatos: indicador.MetodoRecoleccionDatos,
      Proceso: indicador.Proceso,
      Referencias: indicador.Referencias,
      ValorLimite: indicador.ValorLimite,
      ValorObjetivo: indicador.ValorObjetivo,
      Servicio: indicador.Servicio,
    }

    this.read()
    this._table.push(indicadorModel)
    this.save()
  }

  static updateStatus(indicadorId: string) {
    const indicador = this._table.find((x) => x.Id === indicadorId) as IndicadorModel
    if (indicador === null) return

    indicador.IsActivo = !indicador.IsActivo

    localStorage.setItem(INDICADORES_KEY, JSON.stringify(this._table))
  }

  static updateIndicador(indicadorId: string, updateIndicador: CreateIndicadorDTO) {
    const indicador = this._table.find((x) => x.Id === indicadorId) as IndicadorModel
    if (indicador === null) return

    indicador.Nombre = updateIndicador.Nombre
    indicador.Calculos = updateIndicador.Calculos
    indicador.Codigo = updateIndicador.Codigo
    indicador.Definicion = updateIndicador.Definicion
    indicador.FormatoExpresion = updateIndicador.FormatoExpresion
    indicador.FrecuenciaReporte = updateIndicador.FrecuenciaReporte
    indicador.MetodoRecoleccionDatos = updateIndicador.MetodoRecoleccionDatos
    indicador.Proceso = updateIndicador.Proceso
    indicador.Referencias = updateIndicador.Referencias
    indicador.ValorLimite = updateIndicador.ValorLimite
    indicador.ValorObjetivo = updateIndicador.ValorObjetivo
    indicador.Servicio = updateIndicador.Servicio

    localStorage.setItem(INDICADORES_KEY, JSON.stringify(this._table))
  }
}
