interface ParseErrorResult {
  messageId: string
  property: string | undefined
}

const parseError = (error: any): ParseErrorResult => {
  let messageId = 'unknown_error'
  let property
  if (typeof error === 'string') {
    const segments = error.split('.')
    property = segments.length > 2 ? segments[segments.length - 1] : null
    messageId = segments.slice(0, -1).join('.')
  } else if (error.message && error.message.indexOf('Network') != -1) messageId = 'network_error'

  messageId = `error.${messageId}`.toUpperCase()

  return {messageId, property: property ?? undefined}
}

const parseAndFormatError = (error: any, formatMessageFn: any) => {
  const {messageId, property} = parseError(error)

  return `${formatMessageFn({id: messageId}, {property})}.`
}

export {parseError, parseAndFormatError}
