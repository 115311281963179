import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, Redirect, useParams, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {getVerifyHashByURL, resetPassword} from '../../modules/auth/redux/CRUD/AuthCRUD'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'

const initialValues = {
  txtPassword: '',
  txtPasswordRepeat: '',
}

const resetPasswordSchema = Yup.object().shape({
  txtPassword: Yup.string()
    .min(8, 'Mínimo 8 simbolos')
    .max(50, 'Máximo 50 simbolos')
    .required('La contraseña es requerida'),
  txtPasswordRepeat: Yup.string()
    .min(8, 'Mínimo 8 simbolos')
    .max(50, 'Máximo 50 simbolos')
    .required('La contraseña es requerida')
    .oneOf([Yup.ref('txtPassword'), null], 'La contraseña debe ser igual en ambos campos'),
})

export function ResetPassword() {
  const history = useHistory()
  const intl = useIntl()

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [isValid, setIsValid] = useState(false)
  const [isValidatingHash, setIsValidatingHash] = useState(true)

  type Params = {
    hash: string
  }
  let {hash} = useParams<Params>()

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        resetPassword(hash, values.txtPassword)
          .then(() => {
            setHasErrors(false)
            setLoading(false)
            setTimeout(() => {
              history.push('/auth/login')
            }, 4000)
          })
          .catch((e) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)

            setStatus('Los datos son incorrectos')
          })
      }, 1000)
    },
  })

  // validar hash

  useEffect(() => {
    getVerifyHashByURL(hash)
      .then(() => {
        setIsValid(true)
      })
      .catch(() => setIsValid(false))
      .finally(() => setIsValidatingHash(false))
  }, [hash])

  if (!isValidatingHash && isValid) {
    return (
      <>
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={formik.handleSubmit}
        >
          {/* begin::Title */}
          {hasErrors === true && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>
                {intl.formatMessage({id: 'AUTH.VALIDATION.INVALID_FIELD'})}
              </div>
            </div>
          )}

          <div className='text-center mb-10'>
            <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'AUTH.RESET.TITLE'})}</h1>
          </div>

          {hasErrors === false ? (
            <div className='mb-10 bg-light-info p-8 rounded'>
              <div className='text-info'>{intl.formatMessage({id: 'AUTH.RESET.SUCCESS'})}</div>
            </div>
          ) : (
            <>
              <div className='text-center mb-10'>
                <div className='text-gray-400 fw-bold fs-4'>
                  {intl.formatMessage({id: 'AUTH.RESET.DESC'})}
                </div>
                {/* end::Link */}
              </div>
              <div className='fv-row mb-10'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>
                  {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
                </label>
                <input
                  type='password'
                  placeholder='Contraseña'
                  autoComplete='off'
                  {...formik.getFieldProps('txtPassword')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.txtPassword && formik.errors.txtPassword,
                    },
                    {
                      'is-valid': formik.touched.txtPassword && !formik.errors.txtPassword,
                    }
                  )}
                />
                {formik.touched.txtPassword && formik.errors.txtPassword && (
                  <div className='fv-plugins-message-container text-danger'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.txtPassword}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-10'>
                <label className='form-label fw-bolder text-gray-900 fs-6'>
                  Repetir contraseña
                </label>
                <input
                  type='password'
                  placeholder='Repetir contraseña'
                  autoComplete='off'
                  {...formik.getFieldProps('txtPasswordRepeat')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid':
                        formik.touched.txtPasswordRepeat && formik.errors.txtPasswordRepeat,
                    },
                    {
                      'is-valid':
                        formik.touched.txtPasswordRepeat && !formik.errors.txtPasswordRepeat,
                    }
                  )}
                />
                {formik.touched.txtPasswordRepeat && formik.errors.txtPasswordRepeat && (
                  <div className='fv-plugins-message-container text-danger'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.txtPasswordRepeat}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <Link to='/auth/login'>
                  <button
                    type='button'
                    id='kt_login_password_reset_form_cancel_button'
                    className='btn btn-lg btn-light-primary fw-bolder'
                    name='btnCancelar'
                  >
                    {intl.formatMessage({id: 'AUTH.LOGIN.BUTTON_CANCEL'})}
                  </button>
                </Link>
                &nbsp;
                <button
                  type='submit'
                  name='btnSubmit'
                  id='kt_password_reset_submit'
                  className='btn btn-lg btn-primary fw-bolder ms-1'
                  disabled={
                    formik.isSubmitting ||
                    !formik.isValid ||
                    loading ||
                    formik.getFieldProps('currentPassword').value === ''
                  }
                >
                  <span className='indicator-label'>
                    {intl.formatMessage({id: 'AUTH.RESET.BUTTON'})}
                  </span>
                  {loading && (
                    <span className='indicator-progress'>
                      {intl.formatMessage({id: 'WAITING'})}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </>
          )}
        </form>
      </>
    )
  } else if (isValidatingHash) {
    return (
      <div className='splash-screen'>
        <img src={toAbsoluteUrl('/media/logos/logo-compact.svg')} alt='Start logo' />
        <span>Cargando...</span>
      </div>
    )
  } else {
    return <Redirect to={'/'} />
  }
}
