import axios from 'axios'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'
import {CreateInstitutionDTO} from '../../../../models/DTOs/institucion/CreateInstitutionDTO'
import {UpdateInstitucionDTO} from '../../../../models/DTOs/institucion/UpdateInstitucionDTO'

export const GET_INSTITUTIONS = `${API_URL}/instituciones`
export const CREATE_INSTITUTION = `${API_URL}/instituciones`
export const UPDATE_INSTITUTION_STATUS = `${API_URL}/instituciones/:id/status`
export const UPDATE_INSTITUTION = `${API_URL}/instituciones/:id`
export const GET_INSTITUTION_DETAILS = `${API_URL}/instituciones/alta/:id`
export const GET_INSTITUTIONS_DETAILS = `${API_URL}/instituciones/alta`

export function getInstituciones() {
  return axios.get(GET_INSTITUTIONS)
}

export function getInstitucionDetalles(institucionId: string) {
  return axios.get(GET_INSTITUTION_DETAILS.replace(':id', institucionId))
}

export function updateInstitutionStatus(institucionId: string) {
  return axios.post(UPDATE_INSTITUTION_STATUS.replace(':id', institucionId))
}

export function createInstitution(institucion: CreateInstitutionDTO) {
  return axios.post(CREATE_INSTITUTION, institucion)
}

export function updateInstitution(institucionId: string, updateInstitucion: UpdateInstitucionDTO) {
  return axios.post(UPDATE_INSTITUTION.replace(':id', institucionId), updateInstitucion)
}

export function getInstitucionesDetalles() {
  return axios.get(GET_INSTITUTIONS_DETAILS)
}
