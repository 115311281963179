import {useIntl} from 'react-intl'
import {FC} from 'react'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../models/UserModel'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const DashboardWrapper: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <>
      {/* text */}
      <div className='p-5 text-center bg-light'>
        <h1 className='mb-3 display-3'>Hola {user.Nombre}!</h1>
        <img
          alt='Logo'
          className='h-70px logo'
          src={toAbsoluteUrl('/media/logos/logo_gm_qi_largo.png')}
        />
      </div>
    </>
  )
}

export {DashboardWrapper}
