import axios from 'axios'
import {CreateUsuarioDTO} from '../../../../models/DTOs/usuario/CreateUsuarioDTO'
import {UsuarioDTO} from '../../../../models/DTOs/usuario/UsuarioDTO'
import {API_URL} from '../../../../../_metronic/helpers/API_URLHelper'

export const GET_USERS_LIST = `${API_URL}/usuarios`
export const UPDATE_STATUS = `${API_URL}/usuarios/:id/status`
export const CREATE_NEW_USER = `${API_URL}/usuarios`
export const UPDATE_USER = `${API_URL}/usuarios/:id`

export function getListaUsuarios() {
  return axios.get(GET_USERS_LIST)
}

export function updateStatus(usuarioId: string) {
  return axios.post(UPDATE_STATUS.replace(':id', usuarioId))
}

export function createNewUser(newUser: CreateUsuarioDTO) {
  return axios.post(CREATE_NEW_USER, newUser)
}

export function updateUser(usuario: UsuarioDTO) {
  return axios.post(UPDATE_USER.replace(':id', usuario.Id), {
    Nombre: usuario.Nombre,
    Email: usuario.Email,
    Rol: usuario.Rol,
  })
}
